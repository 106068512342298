import { PlanTagEnumEnum } from '@amzn/fox-den-cost-planning-lambda';
import { TFunction } from 'i18next';
import CopyText from 'src/common/copy-text/CopyText';
import EditPlanDescriptionButton from 'src/pages/commons/plan-actions/EditPlanDescriptionButton';
import EditTagsButton from 'src/pages/commons/plan-actions/EditTagsButton';
import { IBatchMetadataParsed } from 'src/utils/planning/batchMetadataModel';
import {
  PLAN_TAG_LABEL_MAP,
  PLAN_TYPES,
  PlanTypeId,
  SUB_GROUP_LABEL_MAP,
  SubGroupId,
} from 'src/utils/planning/planetModel';

export enum PlanDetailsMetadataFields {
  PLAN_NAME = 'planName',
  PLAN_ID = 'planId',
  PLAN_TYPE = 'planType',
  BUSINESS_GROUP = 'businessGroup',
  SUB_GROUP = 'subGroup',
  REGION = 'region',
  COUNTRIES = 'countries',
  PLAN_CYCLE = 'planCycle',
  PERIOD_TYPE = 'periodType',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  COGNOS_CURRENCY_SCENARIO = 'cognosCurrencyScenario',
  DEFAULT_CURRENCY = 'defaultCurrency',
  SLOT = 'slot',
  SLOT_DESCRIPTION = 'slotDescription',
  TAGS = 'tags',
}

interface IPlanDetailsDisplayField {
  field: PlanDetailsMetadataFields;
  fieldLabel: string;
  contentDisplay: (batchMetadata: IBatchMetadataParsed | undefined) => JSX.Element;
  shouldHide?: (batchMetadata: IBatchMetadataParsed | undefined) => boolean;
}

export const getGeneralPlanDetailsDisplaySettings = (
  t: TFunction<'translation', undefined>,
): IPlanDetailsDisplayField[] => [
  {
    field: PlanDetailsMetadataFields.PLAN_NAME,
    fieldLabel: t('plan_name_header'),
    contentDisplay: (batchMetadata: IBatchMetadataParsed | undefined) => (
      <div>{batchMetadata?.batchName ?? '-'}</div>
    ),
  },
  {
    field: PlanDetailsMetadataFields.PLAN_ID,
    fieldLabel: t('plan_id_header'),
    contentDisplay: (batchMetadata: IBatchMetadataParsed | undefined) => (
      <CopyText
        copyText={batchMetadata?.batchId ?? '-'}
        copyButtonLabel={t('copy_plan_button_label')}
        successText={t('copy_plan_success')}
        errorText={t('copy_plan_error')}
      />
    ),
  },
  {
    field: PlanDetailsMetadataFields.PLAN_TYPE,
    fieldLabel: t('plan_type_label'),
    contentDisplay: (batchMetadata: IBatchMetadataParsed | undefined) => (
      <div>{PLAN_TYPES.find((item) => item.value === batchMetadata?.costType)?.label ?? '-'}</div>
    ),
  },
  {
    field: PlanDetailsMetadataFields.BUSINESS_GROUP,
    fieldLabel: t('business_group_label'),
    contentDisplay: (batchMetadata: IBatchMetadataParsed | undefined) => (
      <div>{batchMetadata?.businessGroup ?? '-'}</div>
    ),
  },
  {
    field: PlanDetailsMetadataFields.SUB_GROUP,
    fieldLabel: t('sub_group_label'),
    contentDisplay: (batchMetadata: IBatchMetadataParsed | undefined) => (
      <div>
        {Object.keys(SUB_GROUP_LABEL_MAP).includes(batchMetadata?.subGroup ?? '')
          ? SUB_GROUP_LABEL_MAP[batchMetadata?.subGroup as SubGroupId]
          : batchMetadata?.subGroup ?? '-'}
      </div>
    ),
    shouldHide: (batchMetadata) => batchMetadata?.costType === PlanTypeId.CF_CONSOLIDATION,
  },
  {
    field: PlanDetailsMetadataFields.REGION,
    fieldLabel: t('region_label'),
    contentDisplay: (batchMetadata: IBatchMetadataParsed | undefined) => (
      <div>{batchMetadata?.region ?? '-'}</div>
    ),
  },
  {
    field: PlanDetailsMetadataFields.COUNTRIES,
    fieldLabel: t('countries_label'),
    contentDisplay: (batchMetadata: IBatchMetadataParsed | undefined) => (
      <div>{batchMetadata?.countries?.join(', ') ?? '-'}</div>
    ),
  },
  {
    field: PlanDetailsMetadataFields.PLAN_CYCLE,
    fieldLabel: t('planning_cycle_label'),
    contentDisplay: (batchMetadata: IBatchMetadataParsed | undefined) => (
      <div>{batchMetadata?.planCycle ?? '-'}</div>
    ),
  },
  {
    field: PlanDetailsMetadataFields.PERIOD_TYPE,
    fieldLabel: t('period_type_label'),
    contentDisplay: (batchMetadata: IBatchMetadataParsed | undefined) => (
      <div>{batchMetadata?.periodType ?? '-'}</div>
    ),
    shouldHide: () => true,
  },
  {
    field: PlanDetailsMetadataFields.START_DATE,
    fieldLabel: t('start_date_label'),
    contentDisplay: (batchMetadata: IBatchMetadataParsed | undefined) => (
      <div>{batchMetadata?.startDate ?? '-'}</div>
    ),
    shouldHide: (batchMetadata) => batchMetadata?.costType === PlanTypeId.CF_CONSOLIDATION,
  },
  {
    field: PlanDetailsMetadataFields.END_DATE,
    fieldLabel: t('end_date_label'),
    contentDisplay: (batchMetadata: IBatchMetadataParsed | undefined) => (
      <div>{batchMetadata?.endDate ?? '-'}</div>
    ),
    shouldHide: (batchMetadata) => batchMetadata?.costType === PlanTypeId.CF_CONSOLIDATION,
  },
  {
    field: PlanDetailsMetadataFields.COGNOS_CURRENCY_SCENARIO,
    fieldLabel: t('cognos_currency_scenario_label'),
    contentDisplay: (batchMetadata: IBatchMetadataParsed | undefined) => (
      <div>{batchMetadata?.currencyScenario ?? '-'}</div>
    ),
    shouldHide: (_) => true,
  },
  {
    field: PlanDetailsMetadataFields.DEFAULT_CURRENCY,
    fieldLabel: t('default_currency_label'),
    contentDisplay: (batchMetadata: IBatchMetadataParsed | undefined) => (
      <div>{batchMetadata?.currency ?? '-'}</div>
    ),
    shouldHide: (batchMetadata: IBatchMetadataParsed | undefined) =>
      batchMetadata?.costType !== PlanTypeId.CAPEX,
  },
  {
    field: PlanDetailsMetadataFields.SLOT,
    fieldLabel: t('slot_label'),
    contentDisplay: (batchMetadata: IBatchMetadataParsed | undefined) => (
      <div>{batchMetadata?.slot ?? '-'}</div>
    ),
  },
  {
    field: PlanDetailsMetadataFields.SLOT_DESCRIPTION,
    fieldLabel: t('plan_description_label'),
    contentDisplay: (batchMetadata: IBatchMetadataParsed | undefined) => (
      <div>
        {batchMetadata?.slotDescription ?? '-'}
        <EditPlanDescriptionButton />
      </div>
    ),
  },
  {
    field: PlanDetailsMetadataFields.TAGS,
    fieldLabel: t('tags_label'),
    contentDisplay: (batchMetadata: IBatchMetadataParsed | undefined) => {
      const tags = [];
      if (batchMetadata?.planTag && batchMetadata?.planTag !== PlanTagEnumEnum._)
        tags.push(PLAN_TAG_LABEL_MAP[batchMetadata.planTag]);
      if (batchMetadata?.finalTag) tags.push(t('final_tag_text'));
      return (
        <div>
          {tags.join(', ') || '-'}
          <EditTagsButton />
        </div>
      );
    },
  },
];
