import { DateTime } from 'luxon';
import {
  IBusinessGroupOption,
  PeriodTypeId,
  IPeriodTypeOption,
  PlanTypeId,
  IPlanTypeOption,
  IRegionOption,
  ISubGroupOption,
  PlanningCycleId,
  CURRENCY_USD,
  LOCAL_CURRENCY,
  RO_CURRENT_STATE_OPTION,
  RegionId,
  OTR_PLAN_TYPES,
  isUTRPlan,
} from 'src/utils/planning/planetModel';
import { getCurrentYear, getInclusiveReportingTimeRange, getPrevWeekday } from 'src/utils/time';

export enum PlanCreateFieldIds {
  BUSINESS_GROUP = 'businessGroup',
  REGION = 'region',
  COUNTRIES = 'countries',
  SUB_GROUP = 'subGroup',
  PLAN_TYPE = 'planType',
  PLAN_CYCLE = 'planCycle',
  START_YEAR = 'startYear',
  PERIOD_TYPE = 'periodType',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  CURRENCY = 'currency',
  SLOT_DESCRIPTION = 'slotDescription',
}

export interface IOptionDefinition {
  label: string;
  value: string;
  disabled?: boolean;
}

export class PlanCreateFields {
  planType: IPlanTypeOption | null = null;
  businessGroup: IBusinessGroupOption | null = null;
  subGroup: ISubGroupOption | null = null;
  region: IRegionOption | null = null;
  countries: IOptionDefinition[] = [];
  planCycle: IOptionDefinition | null = null;
  startYear: IOptionDefinition | null = null;
  periodType: IPeriodTypeOption | null = null;
  startDate = '';
  endDate = '';
  currency: IOptionDefinition | null = null;
  slotDescription = '';
}

export const getPlanCycleOptions = (formContent: PlanCreateFields) =>
  (() => {
    switch (formContent.planType?.value) {
      case PlanTypeId.REAL_ESTATE:
        return [
          PlanningCycleId.CycleOP1,
          PlanningCycleId.CycleOP2,
          PlanningCycleId.CycleQ2G,
          PlanningCycleId.CycleQ3G,
          PlanningCycleId.CycleQ4G,
        ];
      case PlanTypeId.TOPS_DOWN_FORECAST:
        return [PlanningCycleId.CycleTD];
      case PlanTypeId.CF_CONSOLIDATION:
        return [
          PlanningCycleId.Cycle3YF,
          PlanningCycleId.CycleOP1,
          PlanningCycleId.CycleOP2,
          PlanningCycleId.CycleQ2G,
          PlanningCycleId.CycleQ3G,
          PlanningCycleId.CycleQ4G,
          PlanningCycleId.CycleActuals,
        ];
      default:
        return [
          PlanningCycleId.Cycle3YF,
          PlanningCycleId.CycleOP1,
          PlanningCycleId.CycleOP2,
          PlanningCycleId.CycleQ2G,
          PlanningCycleId.CycleQ3G,
          PlanningCycleId.CycleQ4G,
        ];
    }
  })().map((o) => ({ label: o, value: o }));

export const getPlanYearOptions = () => {
  const currentYear = DateTime.now().year;

  return [currentYear - 1, currentYear, currentYear + 1, currentYear + 2].map((o) => ({
    label: o.toString(),
    value: o.toString(),
  }));
};

const periodTypeMapping: Record<PlanTypeId, PeriodTypeId> = {
  [PlanTypeId.CAPEX]: PeriodTypeId.MONTHLY,
  [PlanTypeId.DEPRECIATION]: PeriodTypeId.MONTHLY,
  [PlanTypeId.REAL_ESTATE]: PeriodTypeId.MONTHLY,
  [PlanTypeId.JANITORIAL]: PeriodTypeId.MONTHLY,
  [PlanTypeId.SECURITY]: PeriodTypeId.MONTHLY,
  [PlanTypeId.THREE_P_TRANSPORTATION]: PeriodTypeId.WEEKLY,
  [PlanTypeId.OTR_WAREHOUSE_TRANSFER]: PeriodTypeId.MONTHLY,
  [PlanTypeId.OTR_DOMESTIC_INBOUND]: PeriodTypeId.MONTHLY,
  [PlanTypeId.OTR_FREIGHT_CAPITALIZATION]: PeriodTypeId.MONTHLY,
  [PlanTypeId.OTR_SUPPLY_TYPE]: PeriodTypeId.MONTHLY,
  [PlanTypeId.TOPS_DOWN_FORECAST]: PeriodTypeId.QUARTERLY,
  [PlanTypeId.UTR_PRODUCTIVITY]: PeriodTypeId.QUARTERLY,
  [PlanTypeId.UTR_COST_HC]: PeriodTypeId.QUARTERLY,
  [PlanTypeId.FIXED_COST_CONSOLIDATION]: PeriodTypeId.MONTHLY,
  [PlanTypeId.CF_CONSOLIDATION]: PeriodTypeId.QUARTERLY,
};

export const getPeriodTypeOptions = (formContent: PlanCreateFields) => {
  if (!formContent.planType) return [];
  const periodType = periodTypeMapping[formContent.planType.value];
  return [{ label: periodType, value: periodType }];
};

export const getDefaultPlanCycleOption = (formContent: PlanCreateFields) => {
  switch (formContent.planType?.value) {
    case PlanTypeId.TOPS_DOWN_FORECAST:
      return { label: PlanningCycleId.CycleTD, value: PlanningCycleId.CycleTD };
    default:
      return { label: PlanningCycleId.CycleOP2, value: PlanningCycleId.CycleOP2 };
  }
};

export const getDefaultPlanYearOption = () => {
  const year = DateTime.now().year.toString();

  return { label: year, value: year };
};

export const getDefaultPeriodTypeOption = (formContent: PlanCreateFields) =>
  getPeriodTypeOptions(formContent)[0];

export const getDefaultCurrencyOption = (formContent: PlanCreateFields) => {
  switch (formContent.planType?.value) {
    case PlanTypeId.CAPEX:
    case PlanTypeId.TOPS_DOWN_FORECAST:
    case PlanTypeId.CF_CONSOLIDATION:
      return { label: CURRENCY_USD, value: CURRENCY_USD };
    default:
      return { label: LOCAL_CURRENCY, value: LOCAL_CURRENCY };
  }
};

export const getCurrencyOptions = (formContent: PlanCreateFields) => {
  switch (formContent.planType?.value) {
    case PlanTypeId.CAPEX:
      return [
        { label: CURRENCY_USD, value: CURRENCY_USD },
        { label: LOCAL_CURRENCY, value: LOCAL_CURRENCY },
      ];
    case PlanTypeId.TOPS_DOWN_FORECAST:
      return [{ label: CURRENCY_USD, value: CURRENCY_USD }];
    case PlanTypeId.CF_CONSOLIDATION:
      return [
        { label: CURRENCY_USD, value: CURRENCY_USD },
        { label: LOCAL_CURRENCY, value: LOCAL_CURRENCY, disabled: true },
      ];
    default:
      return [{ label: LOCAL_CURRENCY, value: LOCAL_CURRENCY }];
  }
};

export const getDefaultCurrencyScenario = () => RO_CURRENT_STATE_OPTION.value;

export const getStartDateEnabledCriteria = (formContent: PlanCreateFields) => (date: Date) => {
  switch (formContent.periodType?.value) {
    case PeriodTypeId.WEEKLY:
      return date.getDay() === 0; // Sunday
    case PeriodTypeId.QUARTERLY:
      return date.getMonth() % 3 === 0; // First day of each quarter
    case PeriodTypeId.MONTHLY:
    default:
      return true;
  }
};

export const getEndDateEnabledCriteria = (formContent: PlanCreateFields) => (date: Date) => {
  switch (formContent.periodType?.value) {
    case PeriodTypeId.WEEKLY:
      return date.getDay() === 6; // Saturday
    case PeriodTypeId.QUARTERLY:
      return date.getMonth() % 3 === 2; // Last month in quarter
    case PeriodTypeId.MONTHLY:
      return true;
    default:
      return true;
  }
};

export const getDefaultStartEndDate = (
  formContent: PlanCreateFields,
): { startDate: string; endDate: string } => {
  const startYear = formContent.startYear?.value
    ? Number.parseInt(formContent.startYear?.value)
    : getCurrentYear();

  const endOfStartYear = DateTime.fromFormat(startYear.toString(), 'yyyy')
    .endOf('year')
    .toFormat('yyyy-MM');

  const endOfStartYearPlusTwo = DateTime.fromFormat((startYear + 2).toString(), 'yyyy')
    .endOf('year')
    .toFormat('yyyy-MM');

  const endOfStartYearPlusThree = DateTime.fromFormat((startYear + 3).toString(), 'yyyy')
    .endOf('year')
    .toFormat('yyyy-MM');

  switch (formContent.periodType?.value) {
    case PeriodTypeId.WEEKLY:
      switch (formContent.planCycle?.value) {
        case PlanningCycleId.CycleOP1:
        case PlanningCycleId.CycleOP2:
          return getInclusiveReportingTimeRange(`${startYear}-01-01`, 12);
        case PlanningCycleId.Cycle3YF:
          return getInclusiveReportingTimeRange(`${startYear}-01-01`, 36);
        case PlanningCycleId.CycleQ2G:
          return getInclusiveReportingTimeRange(`${startYear}-04-01`, 9);
        case PlanningCycleId.CycleQ3G:
          return getInclusiveReportingTimeRange(`${startYear}-07-01`, 6);
        case PlanningCycleId.CycleQ4G:
          return getInclusiveReportingTimeRange(`${startYear}-10-01`, 3);
      }
      break;
    case PeriodTypeId.MONTHLY:
      switch (formContent.planCycle?.value) {
        case PlanningCycleId.CycleOP1:
        case PlanningCycleId.CycleOP2:
          return { startDate: `${startYear}-01`, endDate: endOfStartYear };
        case PlanningCycleId.CycleQ2G:
          return { startDate: `${startYear}-04`, endDate: endOfStartYear };
        case PlanningCycleId.CycleQ3G:
          return { startDate: `${startYear}-07`, endDate: endOfStartYear };
        case PlanningCycleId.CycleQ4G:
          return { startDate: `${startYear}-10`, endDate: endOfStartYear };
        case PlanningCycleId.Cycle3YF:
          return { startDate: `${startYear}-01`, endDate: endOfStartYearPlusTwo };
      }
      break;
    case PeriodTypeId.QUARTERLY:
      switch (formContent.planCycle?.value) {
        case PlanningCycleId.CycleTD:
          return { startDate: `${startYear}-01`, endDate: endOfStartYearPlusThree };
        case PlanningCycleId.CycleOP1:
        case PlanningCycleId.CycleOP2:
          return { startDate: `${startYear}-01`, endDate: endOfStartYear };
        case PlanningCycleId.CycleQ2G:
          return { startDate: `${startYear}-04`, endDate: endOfStartYear };
        case PlanningCycleId.CycleQ3G:
          return { startDate: `${startYear}-07`, endDate: endOfStartYear };
        case PlanningCycleId.CycleQ4G:
          return { startDate: `${startYear}-10`, endDate: endOfStartYear };
        case PlanningCycleId.Cycle3YF:
          return { startDate: `${startYear}-01`, endDate: endOfStartYearPlusTwo };
      }
      break;
  }

  return { startDate: '', endDate: '' };
};

export const convertStartEndDateForPlanCreate = (formContent: PlanCreateFields) => {
  if (formContent.periodType?.value === PeriodTypeId.WEEKLY) {
    const endDate = getPrevWeekday(
      DateTime.fromFormat(formContent.endDate, 'yyyy-MM-dd'),
      7,
    ).toFormat('yyyy-MM-dd');
    const weeks = Math.ceil(
      DateTime.fromFormat(formContent.endDate, 'yyyy-MM-dd')
        .diff(DateTime.fromFormat(formContent.startDate, 'yyyy-MM-dd'), 'week')
        .toObject().weeks ?? 0,
    );

    return {
      startDate: formContent.startDate,
      endDate,
      startYear: formContent.startYear?.value,
      duration: weeks,
    };
  }
  if (formContent.periodType?.value === PeriodTypeId.MONTHLY) {
    const startDate = DateTime.fromFormat(formContent.startDate, 'yyyy-MM')
      .startOf('month')
      .toFormat('yyyy-MM-dd');
    const endDate = DateTime.fromFormat(formContent.endDate, 'yyyy-MM')
      .startOf('month')
      .toFormat('yyyy-MM-dd');

    const months = Math.ceil(
      DateTime.fromFormat(formContent.endDate, 'yyyy-MM')
        .endOf('month')
        .diff(DateTime.fromFormat(formContent.startDate, 'yyyy-MM').startOf('month'), 'month')
        .toObject().months ?? 0,
    );

    return {
      startDate,
      endDate,
      startYear: formContent.startYear?.value,
      duration: months,
    };
  }

  if (formContent.periodType?.value === PeriodTypeId.QUARTERLY) {
    const startDate = DateTime.fromFormat(formContent.startDate, 'yyyy-MM')
      .startOf('month')
      .toFormat('yyyy-MM-dd');
    const endDate = DateTime.fromFormat(formContent.endDate, 'yyyy-MM')
      .endOf('month')
      .toFormat('yyyy-MM-dd');

    const months = Math.ceil(
      DateTime.fromFormat(formContent.endDate, 'yyyy-MM')
        .endOf('month')
        .diff(DateTime.fromFormat(formContent.startDate, 'yyyy-MM').startOf('month'), 'month')
        .toObject().months ?? 0,
    );

    return {
      startDate,
      endDate,
      startYear: formContent.startYear?.value,
      duration: months,
    };
  }
};

export const getAllowedCountries = (formContent: PlanCreateFields): IOptionDefinition[] => {
  if (!formContent.planType || !formContent.region) return [];

  const REGION_COUNTRIES_MAP: Record<RegionId, string[]> = {
    [RegionId.NA]: ['US', 'CA', 'MX'],
    [RegionId.APAC]: ['JP', 'IN', 'AU', 'SG'],
    [RegionId.EU]: [
      'AT',
      'BE',
      'CZ',
      'FR',
      'DE',
      'IT',
      'IE',
      'LU',
      'NL',
      'PL',
      'PT',
      'SK',
      'ES',
      'SE',
      'UK',
    ],
    [RegionId.LATAM]: ['BR'],
    [RegionId.MENA]: ['EG', 'SA', 'AE', 'TR', 'ZA'],
  };

  const OTR_FC_NA_COUNTRIES = ['US'];

  const OTR_WHT_EU_COUNTRIES = ['UK', 'IE', 'DE', 'PL', 'CZ', 'SK', 'FR', 'IT', 'ES'];

  const OTR_DIB_EU_COUNTRIES = ['DE', 'PL', 'FR', 'IT', 'ES', 'UK', 'SE', 'NL'];

  const OTR_FC_EU_COUNTRIES = ['DE', 'ES', 'FR', 'IT', 'NL', 'PL', 'SE', 'BE', 'UK'];

  const OTR_MENA_COUNTRIES = ['EG', 'SA', 'AE'];

  const SUPPLY_TYPE_NA_COUNTRIES = ['US'];

  const SUPPLY_TYPE_EU_COUNTRIES = ['UK', 'DE', 'FR', 'IT', 'ES'];

  const TOPS_DOWN_US_COUNTRIES = ['US', 'CA'];

  const TOPS_DOWN_EU_COUNTRIES = ['EU5'];

  if (
    formContent.planType?.value === PlanTypeId.OTR_FREIGHT_CAPITALIZATION &&
    formContent.region?.value === RegionId.NA
  ) {
    return OTR_FC_NA_COUNTRIES.map((o) => ({ label: o, value: o, disabled: true }));
  }

  if (
    formContent.planType?.value === PlanTypeId.OTR_WAREHOUSE_TRANSFER &&
    formContent.region?.value === RegionId.EU
  ) {
    return OTR_WHT_EU_COUNTRIES.map((o) => ({ label: o, value: o, disabled: true }));
  }

  if (
    formContent.planType?.value === PlanTypeId.OTR_DOMESTIC_INBOUND &&
    formContent.region?.value === RegionId.EU
  ) {
    return OTR_DIB_EU_COUNTRIES.map((o) => ({ label: o, value: o, disabled: true }));
  }

  if (
    formContent.planType?.value === PlanTypeId.OTR_FREIGHT_CAPITALIZATION &&
    formContent.region?.value === RegionId.EU
  ) {
    return OTR_FC_EU_COUNTRIES.map((o) => ({ label: o, value: o, disabled: true }));
  }

  if (
    formContent.planType?.value === PlanTypeId.OTR_FREIGHT_CAPITALIZATION &&
    formContent.region?.value === RegionId.MENA
  ) {
    return OTR_MENA_COUNTRIES.map((o) => ({ label: o, value: o, disabled: true }));
  }

  if (
    formContent.planType?.value === PlanTypeId.OTR_SUPPLY_TYPE &&
    formContent.region?.value === RegionId.NA
  ) {
    return SUPPLY_TYPE_NA_COUNTRIES.map((o) => ({ label: o, value: o, disabled: true }));
  }

  if (
    formContent.planType?.value === PlanTypeId.OTR_SUPPLY_TYPE &&
    formContent.region?.value === RegionId.EU
  ) {
    return SUPPLY_TYPE_EU_COUNTRIES.map((o) => ({ label: o, value: o, disabled: true }));
  }

  if (
    formContent.planType?.value === PlanTypeId.TOPS_DOWN_FORECAST &&
    formContent.region?.value === RegionId.NA
  ) {
    return TOPS_DOWN_US_COUNTRIES.map((o) => ({ label: o, value: o }));
  }

  if (
    formContent.planType?.value === PlanTypeId.TOPS_DOWN_FORECAST &&
    formContent.region?.value === RegionId.EU
  ) {
    return TOPS_DOWN_EU_COUNTRIES.map((o) => ({ label: o, value: o }));
  }

  return REGION_COUNTRIES_MAP[formContent.region.value].map((o) => ({ label: o, value: o }));
};

export const getDefaultCountries = (formContent: PlanCreateFields): IOptionDefinition[] => {
  if (!formContent.planType || !formContent.region) return [];

  const CAPEX_EU_DEFAULT_COUNTRIES = ['UK', 'IE', 'DE', 'PL', 'CZ', 'SK', 'FR', 'IT', 'ES'];

  if (formContent.planType?.value === PlanTypeId.CAPEX) {
    if (formContent.region?.value === RegionId.EU) {
      return CAPEX_EU_DEFAULT_COUNTRIES.map((o) => ({ label: o, value: o }));
    }
    return getAllowedCountries(formContent);
  }

  if (
    formContent.region?.value === RegionId.EU &&
    OTR_PLAN_TYPES.has(formContent.planType?.value)
  ) {
    return getAllowedCountries(formContent);
  }

  if (formContent.planType?.value === PlanTypeId.OTR_FREIGHT_CAPITALIZATION) {
    return getAllowedCountries(formContent);
  }

  if (formContent.planType?.value === PlanTypeId.TOPS_DOWN_FORECAST) {
    return [getAllowedCountries(formContent)[0]];
  }

  if (formContent.planType?.value === PlanTypeId.FIXED_COST_CONSOLIDATION) {
    return getAllowedCountries(formContent);
  }

  const CF_CONSOLIDATION_NA_DEFAULT_COUNTRIES = ['US', 'CA'];

  if (formContent.planType?.value === PlanTypeId.CF_CONSOLIDATION) {
    return CF_CONSOLIDATION_NA_DEFAULT_COUNTRIES.map((o) => ({ label: o, value: o }));
  }

  return [];
};

export const shouldDisableEditingStartDate = (formContent: PlanCreateFields) =>
  !formContent.planCycle || isUTRPlan(formContent.planType?.value);

export const shouldDisableEditingEndDate = (formContent: PlanCreateFields) =>
  !formContent.planCycle || isUTRPlan(formContent.planType?.value);

export const shouldHideCurrencyDisplay = (formContent: PlanCreateFields) =>
  ![PlanTypeId.CAPEX, PlanTypeId.CF_CONSOLIDATION].includes(formContent.planType?.value as any);

export const shouldHideDateDisplay = (formContent: PlanCreateFields) =>
  [PlanTypeId.CF_CONSOLIDATION].includes(formContent.planType?.value as any);
