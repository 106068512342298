import { ColDef } from 'ag-grid-enterprise';
import { capitalize } from 'lodash';
import { ITableDataSchemaColumnList } from 'src/utils/planning/batchMetadataModel';

// Headers that have a hardcoded replacement
const specialHeaders: Record<string, string> = {
  useful_life: 'Useful Life in Months',
  lease_term: 'Lease Term in Months',
  su_useful_life: 'SU Useful Life in Months',
  re_useful_life: 'RE Useful Life in Months',
};

// Header words that have a hardcoded capitalization style
const specialTokens: Record<string, string> = {
  id: 'ID',
  isd: 'ISD',
  su: 'SU',
  re: 'RE',
  car: 'CAR',
  rou: 'ROU',
  aro: 'ARO',
  gl: 'GL',
  sqft: 'sqft',
  capex: 'CapEx',
  ctl: 'CTL',
  npv: 'NPV',
  coa: 'COA',
  tco: 'TCO',
  or: 'or',
  uid: 'UID',
  lsa: 'LSA',
  oem: 'OEM',
  usd: 'USD',
};

const convertFieldToHeader = (field: string) => {
  if (field in specialHeaders) {
    return specialHeaders[field];
  }

  return field
    .toLowerCase()
    .split('_')
    .map((token) => {
      if (token in specialTokens) {
        return specialTokens[token];
      }
      return capitalize(token);
    })
    .join(' ');
};

export const getColumnDefs = (columnList: ITableDataSchemaColumnList | undefined) => {
  if (!columnList) {
    return null;
  }

  const tableConfig: ColDef[] = columnList.map((item) => ({
    headerName: convertFieldToHeader(item.dimensionName),
    field: item.dimensionName,
    enablePivot: true,
    enableRowGroup: true,
    enableValue: true,
  }));

  tableConfig[0] = {
    ...tableConfig[0],
    checkboxSelection: true,
    headerCheckboxSelection: true,
  };

  return tableConfig;
};
