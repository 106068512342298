import url from 'url';
import { Storage } from 'aws-amplify';
import { isEqual, split } from 'lodash';
import { parse } from 'papaparse';
// import { envConfig } from 'configuration/aws-exports';
export const S3_COST_PLANNING_BUCKET = 'fox-cost-planning-gamma';
export const S3_COST_PLANNING_BUCKET_WRITE_ONLY = 'fox-cost-planning-write-only-gamma';

export interface IS3DataLocator {
  bucket: string;
  key: string | null;
}

export const parseS3UriToLocator = (s3Uri: string) => {
  const uri = url.parse(s3Uri, true);
  if (uri.protocol !== 's3:') {
    throw new Error(`Invalid S3 URI: ${s3Uri}`);
  }
  const bucket = uri.host;
  if (!bucket) {
    throw new Error(`Invalid S3 URI with no bucket: ${s3Uri}`);
  }
  let key: string | null = null;
  if (!(!uri.pathname || uri.pathname.length <= 1)) {
    // not s3://bucket or s3://bucket/
    // Remove the leading '/'.
    key = uri.pathname.substring(1);
  }
  if (key !== null) {
    key = decodeURIComponent(key);
  }
  return {
    bucket,
    key,
  };
};

export const getDownloadInfo = (key: string) => {
  const tokens = split(key, '/');
  const url = split(key, '/', tokens.length - 1).join('/') + '/';
  const fileName = tokens[tokens.length - 1];
  return { url, fileName };
};

export const downloadS3Data = async (dataLocator: IS3DataLocator) => {
  const bucket = S3_COST_PLANNING_BUCKET;
  if (!dataLocator.key) {
    return '';
  }
  const locatorInfo = getDownloadInfo(dataLocator.key);
  // We cannot use configStorage method. Otherwise, when downloading multiple datasets simultaneously, Storage may
  // use wrong config to download.
  const result = await Storage.get(locatorInfo.fileName, {
    bucket,
    customPrefix: { public: locatorInfo.url },
    level: 'public',
  });
  const content = await fetch(result.toString());
  const data = await content.text();
  return data;
};

const normalizeEndOfLine = (data: string) => data.replace('\r\n', '\n');

export const convertCsvToJsonObject = (csvString: string, columns: null | string[] = null) => {
  const lines: string[][] = parse(normalizeEndOfLine(csvString), {
    skipEmptyLines: true,
  }).data as string[][];
  const result = [];

  // If header is not passed in, treat the first row as header
  const headers: string[] = columns ? columns : (lines[0] as string[]);
  const startIndex = columns ? 0 : 1;

  for (let i = startIndex; i < lines.length; i++) {
    const obj: Record<string, string> = {};
    const currentLine = lines[i];
    if (isEqual(headers, currentLine)) {
      // Skip the row if it equals to header.
      continue;
    }
    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentLine[j];
    }
    result.push(obj);
  }

  return result;
};

/**
 * Config amplify Storage object for upload file
 */
export const configStorage = (pathPrefix: string, bucket: string) => {
  const s3Config = {
    bucket,
    customS3Prefix: {
      public: pathPrefix,
    },
    level: 'public',
  };
  Storage.configure({
    bucket: s3Config.bucket,
    customPrefix: s3Config.customS3Prefix,
    level: 'public',
  });
};

export const putInS3 = (
  fileName: string,
  fileData: File,
  fileType: string,
  progressCallback: ((progress: number) => void) | null = null,
) =>
  Storage.put(fileName, fileData, {
    contentType: fileType,
    progressCallback(progress: any) {
      progressCallback?.((progress.loaded / progress.total) * 100);
    },
  });
