import * as React from "react";
import Wizard from "@amzn/awsui-components-react/polaris/wizard";
import {Button, Container, FormField, Header, Input, Link, SpaceBetween} from "@amzn/awsui-components-react/polaris";
import {KeyValuePairs} from "@amzn/awsui-components-react";

export default () => {
  const [
    activeStepIndex,
    setActiveStepIndex
  ] = React.useState(0);
  // @ts-ignore
  return (
    <Wizard
      i18nStrings={{
        stepNumberLabel: stepNumber =>
          `Step ${stepNumber}`,
        collapsedStepsLabel: (stepNumber, stepsCount) =>
          `Step ${stepNumber} of ${stepsCount}`,
        skipToButtonLabel: (step, stepNumber) =>
          `Skip to ${step.title}`,
        navigationAriaLabel: "Steps",
        cancelButton: "Cancel",
        previousButton: "Previous",
        nextButton: "Proceed and Review",
        submitButton: "View output analysis",
        optional: "non-mandatory"
      }}
      onNavigate={({ detail }) =>
        setActiveStepIndex(detail.requestedStepIndex)
      }
      activeStepIndex={activeStepIndex}
      allowSkipTo
      steps={[
        {
          title: "Transform granularity of loads",
          info: <Link variant="info">Info</Link>,
          description:
            "Utilize historical actuals, prelim loads\n" +
            "input, and supply strategy attainment\n" +
            "to transform the granularity of loads\n" +
            "from Network to Business and Driver\n" +
            "to Distance Band",
          content: (
            <Container
              header={
                <Header variant="h2">
                  Input
                </Header>
              }
            >
              <SpaceBetween direction="vertical" size="l">
                <FormField label="Prelim loads">
                  <Input  value={""}/>
                </FormField>
                <Button
                  onClick={() => {}}
                >
                  Upload
                </Button>
                <FormField label="Supply Strategy attainment">
                  <Input  value={""}/>
                </FormField>
                <Button
                  onClick={() => {}}
                >
                  Upload
                </Button>
                <FormField label="Historical loads (by Sub-Supply,Distance Band,Business, and Driver Type)">
                  <Input  value={""}/>
                </FormField>
              </SpaceBetween>
            </Container>
          )
        },
        {
          title: "Apply data science model logic",
          description:
            "Apply data science model on transformed granularity of loads",
          content: (
            <Container
              header={
                <Header variant="h2">
                  Input
                </Header>
              }
            >
              <SpaceBetween direction="vertical" size="l">
                <FormField label="Transformed granularity of loads">
                  <Input  value={""}/>
                </FormField>
                <FormField label="Data science model">
                  <Input  value={""}/>
                </FormField>
                <Button
                  onClick={() => {}}
                >
                  Apply
                </Button>
              </SpaceBetween>
            </Container>
          ),
          isOptional: false
        },
        {
          title: "Calculate final LDT",
          content: (
            <Container
              header={
                <Header variant="h2">
                  Input
                </Header>
              }
            >
              <SpaceBetween direction="vertical" size="l">
                <FormField label="Historical actuals for LDT">
                  <Input  value={""}/>
                </FormField>
                <FormField label="Network LDT input forecast">
                  <Input  value={""}/>
                </FormField>
              </SpaceBetween>
            </Container>
          ),
          isOptional: false
        },
        {
          title: "Apply override on Final LDT calculation",
          content: (
            <Container
              header={
                <Header variant="h2">
                  Input
                </Header>
              }
            >
              <SpaceBetween direction="vertical" size="l">
                <FormField label="Final LPT">
                  <Input  value={""}/>
                </FormField>
                <FormField label="Override for LPT">
                  <Input  value={""}/>
                </FormField>
              </SpaceBetween>
            </Container>
          ),
          isOptional: false
        },
        {
          title: "Calculate total fuel and total tolls",
          content: (
            <Container
              header={
                <Header variant="h2">
                  Input
                </Header>
              }
            >
              <SpaceBetween direction="vertical" size="l">
                <FormField label="Fuel input">
                  <Input  value={""}/>
                </FormField>
              </SpaceBetween>
            </Container>
          ),
          isOptional: false
        },
        {
          title: "Calculate the metrics for AM Cost",
          content: (
            <Container
              header={
                <Header variant="h2">
                  Input
                </Header>
              }
            >
              <SpaceBetween direction="vertical" size="l">
                <FormField label="Historical actuals for Speed">
                  <Input  value={""}/>
                </FormField>
                <FormField label="LDT, LoH and Loads">
                  <Input  value={""}/>
                </FormField>
                <FormField label="Fixed CpBH">
                  <Input  value={""}/>
                </FormField>
              </SpaceBetween>
            </Container>
          ),
          isOptional: false
        },
        {
          title: "Calculate total fuel\n" +
            "and total tolls using\n" +
            "loaded distance",
          content: (
            <Container
              header={
                <Header variant="h2">
                  Input
                </Header>
              }
            >
              <SpaceBetween direction="vertical" size="l">
                <FormField label="Fuel input">
                  <Input  value={""}/>
                </FormField>
              </SpaceBetween>
            </Container>
          ),
          isOptional: false
        },
        {
          title: "Combine tabs for final output",
          content: (
            <Container
              header={
                <Header variant="h2">
                  Input
                </Header>
              }
            >
              <SpaceBetween direction="vertical" size="l">
                <FormField label="TPA input">
                  <Input  value={""}/>
                </FormField>
                <FormField label="Total fuel and total tolls">
                  <Input  value={""}/>
                </FormField>
                <FormField label="Historical country to corridor actuals and distribute loads, cost, miles">
                  <Input  value={""}/>
                </FormField>
                <FormField label="Metrics for AM Cost">
                  <Input  value={""}/>
                </FormField>
                <FormField label="Base Cost Input">
                  <Input  value={""}/>
                </FormField>
              </SpaceBetween>
            </Container>
          ),
          isOptional: false
        },
        {
          title: "Review and Analysis",
          content: (
            <SpaceBetween size="xs">
              <Header
                variant="h3"
                actions={
                  <Button
                    onClick={() => setActiveStepIndex(0)}
                  >
                    Edit
                  </Button>
                }
              >
                Review
              </Header>
              <Container
                header={
                  <Header variant="h2">
                    Inputs
                  </Header>
                }
              >
                <KeyValuePairs
                  columns={3}
                  items={[
                    {
                      label: "TPA input",
                      value: "Value"
                    },
                    {
                      label: "Total fuel and total tolls",
                      value: "Value"
                    },
                    {
                      label: "Metrics for AM Cost",
                      value: "Value"
                    },
                    {
                      label: "Historical country to corridor actuals and distribute loads, cost, miles",
                      value: "Value"
                    },
                    {
                      label: "Base Cost Input",
                      value: "Value"
                    }
                  ]}
                />
              </Container>
            </SpaceBetween>
          )
        }
      ]}
    />
  );
}