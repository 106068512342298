import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { LOCAL_STORAGE_KEYS } from 'src/hooks/local-storage/localstoragekeys';
import { useLocalStoragePreferOverride } from 'src/hooks/local-storage/useLocalStorage';
import { useQueryParams } from 'src/hooks/useQueryParams';
import { BUSINESS_GROUPS, PLAN_TYPES, REGIONS, SUB_GROUPS } from 'src/utils/planning/planetModel';
import {
  BusinessFilterType,
  ISelectedGroupFiltersNonNull,
  SUPPORTED_BUSINESS_GROUPS,
} from 'src/utils/planning/supportedBusinessGroups';

const defaultQueryFilters = {
  planType: PLAN_TYPES[0],
  businessGroup: BUSINESS_GROUPS[0],
  subGroup: SUB_GROUPS[0],
  region: REGIONS[0],
};

/** Initializes queryFilters with from URL, localStorage, or default value
 * (CapEx, GCF, FulfillmentCenter, NA) with decreasing priority.
 *
 * @returns queryFilters and setQueryFilters which can be used to access
 * the list plans page's queryFilters and set them. setQueryFilters will also
 * update the filters in localStorage
 */
const useQueryFilterManager = () => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();

  const queryParamsValid = useMemo(
    () =>
      SUPPORTED_BUSINESS_GROUPS.some(
        (item) =>
          item.planType === queryParams.get(BusinessFilterType.PLAN_TYPE) &&
          item.businessGroup === queryParams.get(BusinessFilterType.BUSINESS_GROUP) &&
          item.subGroup === queryParams.get(BusinessFilterType.SUB_GROUP) &&
          item.region === queryParams.get(BusinessFilterType.REGION),
      ),
    [queryParams],
  );

  const createFilterListFromQueryParams = useCallback(
    () => ({
      planType: PLAN_TYPES.find(
        (item) => item.value === queryParams.get(BusinessFilterType.PLAN_TYPE),
      ),
      businessGroup: BUSINESS_GROUPS.find(
        (item) => item.value === queryParams.get(BusinessFilterType.BUSINESS_GROUP),
      ),
      subGroup: SUB_GROUPS.find(
        (item) => item.value === queryParams.get(BusinessFilterType.SUB_GROUP),
      ),
      region: REGIONS.find((item) => item.value === queryParams.get(BusinessFilterType.REGION)),
    }),
    [queryParams],
  );

  // queryFilters should always exist
  const [queryFilters, setQueryFilters] =
    useLocalStoragePreferOverride<ISelectedGroupFiltersNonNull>(
      LOCAL_STORAGE_KEYS.listPlanFilters,
      queryParamsValid ? (createFilterListFromQueryParams() as any) : null,
      defaultQueryFilters,
    );

  // Keeps the url in sync with the filters without reloading the page
  useEffect(() => {
    if (
      queryFilters.planType?.value !== queryParams.get(BusinessFilterType.PLAN_TYPE) ||
      queryFilters.businessGroup?.value !== queryParams.get(BusinessFilterType.BUSINESS_GROUP) ||
      queryFilters.subGroup?.value !== queryParams.get(BusinessFilterType.SUB_GROUP) ||
      queryFilters.region?.value !== queryParams.get(BusinessFilterType.REGION)
    ) {
      navigate(
        {
          search: createSearchParams({
            [BusinessFilterType.PLAN_TYPE]: queryFilters.planType?.value ?? '',
            [BusinessFilterType.BUSINESS_GROUP]: queryFilters.businessGroup?.value ?? '',
            [BusinessFilterType.SUB_GROUP]: queryFilters.subGroup?.value ?? '',
            [BusinessFilterType.REGION]: queryFilters.region?.value ?? '',
          }).toString(),
        },
        { replace: true },
      );
    }
  }, [navigate, queryFilters, queryParams]);

  return { queryFilters, setQueryFilters };
};

export default useQueryFilterManager;
