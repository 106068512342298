import KatalLogger, { Level } from '@amzn/katal-logger';

const katalLoggerConfig = {
  url: 'https://fid2nbe837.execute-api.us-west-2.amazonaws.com/prod/v1/log',
  logThreshold: Level.INFO,
  maxLogLineSize: 10000,
  logToConsole: false,
};

export const logger = new KatalLogger(katalLoggerConfig);
logger.addErrorListener();
