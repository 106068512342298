import '@amzn/awsui-global-styles/polaris.css';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'src/i18n';
import 'src/logger';
import { AuthProvider } from 'src/providers/AuthProvider';
import { DarkModeProvider } from 'src/providers/DarkModeProvider';
import { MetricsProvider } from 'src/providers/MetricsProvider';
import { NotificationProvider } from 'src/providers/NotificationProvider';
import { QueryProvider } from 'src/providers/QueryProvider';
import { routes } from 'src/routes';
import 'src/index.scss';

// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <StrictMode>
    <MetricsProvider>
      <NotificationProvider>
        <QueryProvider>
          <AuthProvider>
            <DarkModeProvider>
              <RouterProvider router={createBrowserRouter(routes)} />
            </DarkModeProvider>
          </AuthProvider>
        </QueryProvider>
      </NotificationProvider>
    </MetricsProvider>
  </StrictMode>,
);
