import { FullScreen } from '@chiragrupani/fullscreen-react';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.min.css';
import 'ag-grid-enterprise';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.compact.css';

import { RefObject, useMemo } from 'react';
import GridLoadingSpinner from 'src/common/ag-grid/components/GridLoadingSpinner';
import { useDarkModeContext } from 'src/hooks/useDarkModeContext';

interface IAgGridWrapperProps extends AgGridReactProps {
  gridRef: RefObject<AgGridReact>;
  isFullScreen?: boolean;
  setFullScreen?: (isFull: boolean) => void;
  gridHeight?: string | number;
}

export const AgGridBaseWrapper = (props: IAgGridWrapperProps) => {
  const { gridRef, isFullScreen, setFullScreen, gridHeight, ...agGridProps } = props;

  const { isDarkMode } = useDarkModeContext();

  const defaultToolPanel = useMemo(
    () => ({
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
    }),
    [],
  );

  // 100% if full screen, gridHeight parameter otherwise with 60vh as a default
  const height = isFullScreen ? '100%' : gridHeight ?? '60vh';

  return (
    <FullScreen
      isFullScreen={isFullScreen ?? false}
      onChange={(isFull: boolean) => {
        setFullScreen?.(isFull);
      }}
    >
      <div
        className={isDarkMode ? 'ag-theme-balham-dark' : 'ag-theme-balham'}
        style={{
          width: '100%',
          height,
          fontFamily: 'Amazon Ember',
        }}
      >
        <AgGridReact
          ref={gridRef}
          sideBar={defaultToolPanel}
          loadingOverlayComponent={GridLoadingSpinner}
          stopEditingWhenCellsLoseFocus={true}
          {...agGridProps}
        />
      </div>
    </FullScreen>
  );
};
