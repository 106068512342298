type FeatureFlagMap = Record<Feature, StageStatus>;
type StageStatus = Record<Stage, { enabled: boolean; enableDate: string }>;

/**
 * Stage is mapped to FE deployment env instead of BE API env.
 * -----------------------------------------------------------
 * TEST:  desktop.planet.ops-finance.amazon.dev:4321 (local)
 * ALPHA: alpha.v2.planet.ops-finance.amazon.dev
 * BETA:  beta.v2.planet.ops-finance.amazon.dev
 * GAMMA: (currently not exist)
 * PROD:  v2.planet.ops-finance.amazon.dev
 * -----------------------------------------------------------
 */
enum Stage {
  TEST = 'TEST',
  ALPHA = 'ALPHA',
  BETA = 'BETA',
  GAMMA = 'GAMMA',
  PROD = 'PROD',
}

export enum Feature {
  PLAN_JANITORIAL = 'PLAN_JANITORIAL',
  PLAN_SECURITY = 'PLAN_SECURITY',
  PLAN_OTR_FREIGHT_CAPITALIZATION = 'OTR_FREIGHT_CAPITALIZATION',
  PLAN_OTR_SUPPLY_TYPE = 'PLAN_OTR_SUPPLY_TYPE',
  PLAN_TOPS_DOWN_FORECAST = 'PLAN_TOPS_DOWN_FORECAST',
  PLAN_FIXED_COST_CONSOLIDATION = 'PLAN_FIXED_COST_CONSOLIDATION',
  PLAN_CF_CONSOLIDATION = 'PLAN_CF_CONSOLIDATION',
  LOAD_DATASET_BY_ASYNC_UPLOAD_ADITS_API = 'LOAD_DATASET_BY_ASYNC_UPLOAD_ADITS_API',
  ADD_DELTA_TO_GOLF_TAGS = 'ADD_DELTA_TO_GOLF_TAGS',
  PROCESS_FLOW_TAB = 'PROCESS_FLOW_TAB',
  ADD_HOTEL_TO_OSCAR_TAGS = 'ADD_HOTEL_TO_OSCAR_TAGS',
}

const FEATURE_FLAG_MAP: FeatureFlagMap = {
  [Feature.PLAN_JANITORIAL]: {
    [Stage.TEST]: { enabled: true, enableDate: '2024-06-01' },
    [Stage.ALPHA]: { enabled: true, enableDate: '2024-06-01' },
    [Stage.BETA]: { enabled: true, enableDate: '2024-06-01' },
    [Stage.GAMMA]: { enabled: true, enableDate: '2024-06-01' },
    [Stage.PROD]: { enabled: false, enableDate: 'N/A' },
  },
  [Feature.PLAN_SECURITY]: {
    [Stage.TEST]: { enabled: true, enableDate: '2024-06-01' },
    [Stage.ALPHA]: { enabled: true, enableDate: '2024-06-01' },
    [Stage.BETA]: { enabled: true, enableDate: '2024-06-01' },
    [Stage.GAMMA]: { enabled: true, enableDate: '2024-06-01' },
    [Stage.PROD]: { enabled: true, enableDate: '2024-10-10' },
  },
  [Feature.PLAN_OTR_FREIGHT_CAPITALIZATION]: {
    [Stage.TEST]: { enabled: true, enableDate: '2024-07-09' },
    [Stage.ALPHA]: { enabled: true, enableDate: '2024-07-09' },
    [Stage.BETA]: { enabled: true, enableDate: '2024-07-09' },
    [Stage.GAMMA]: { enabled: true, enableDate: '2024-07-09' },
    [Stage.PROD]: { enabled: true, enableDate: '2024-10-23' },
  },
  [Feature.PLAN_TOPS_DOWN_FORECAST]: {
    [Stage.TEST]: { enabled: true, enableDate: '2024-06-20' },
    [Stage.ALPHA]: { enabled: true, enableDate: '2024-06-20' },
    [Stage.BETA]: { enabled: true, enableDate: '2024-06-20' },
    [Stage.GAMMA]: { enabled: true, enableDate: '2024-06-20' },
    [Stage.PROD]: { enabled: true, enableDate: '2024-10-31' },
  },
  [Feature.PLAN_FIXED_COST_CONSOLIDATION]: {
    [Stage.TEST]: { enabled: true, enableDate: '2024-10-21' },
    [Stage.ALPHA]: { enabled: true, enableDate: '2024-10-21' },
    [Stage.BETA]: { enabled: false, enableDate: 'N/A' },
    [Stage.GAMMA]: { enabled: false, enableDate: 'N/A' },
    [Stage.PROD]: { enabled: false, enableDate: 'N/A' },
  },
  [Feature.PLAN_CF_CONSOLIDATION]: {
    [Stage.TEST]: { enabled: true, enableDate: '2024-11-07' },
    [Stage.ALPHA]: { enabled: true, enableDate: '2024-11-07' },
    [Stage.BETA]: { enabled: true, enableDate: '2024-11-07' },
    [Stage.GAMMA]: { enabled: true, enableDate: '2024-11-07' },
    [Stage.PROD]: { enabled: false, enableDate: 'N/A' },
  },
  /**
   * TODO: when removing LOAD_DATASET_BY_ASYNC_UPLOAD_ADITS_API flag, also remove below TriggerTypes since they are no longer used
   *   CAPEX_LOAD_DATASETS, DEPRECIATION_LOAD_DATASETS, REAL_ESTATE_LOAD_DATASETS,
   *   JANITORIAL_LOAD_DATASETS, SECURITY_LOAD_DATASETS, OTR_WHT_LOAD_DATASETS,
   *   OTR_DIB_LOAD_DATASETS, OTR_FC_LOAD_DATASETS, THREE_P_TRANSPORTATION_LOAD_DATASETS,
   *   UTR_PRODUCTIVITY_LOAD_DATASETS, UTR_COST_LOAD_DATASETS, UTR_LARGE_DATA_UPDATE_AND_LOAD
   */
  [Feature.LOAD_DATASET_BY_ASYNC_UPLOAD_ADITS_API]: {
    [Stage.TEST]: { enabled: true, enableDate: '2024-09-09' },
    [Stage.ALPHA]: { enabled: true, enableDate: '2024-09-09' },
    [Stage.BETA]: { enabled: true, enableDate: '2024-09-09' },
    [Stage.GAMMA]: { enabled: true, enableDate: '2024-09-09' },
    [Stage.PROD]: { enabled: true, enableDate: '2024-10-21' },
  },
  [Feature.ADD_DELTA_TO_GOLF_TAGS]: {
    [Stage.TEST]: { enabled: true, enableDate: '2024-09-27' },
    [Stage.ALPHA]: { enabled: true, enableDate: '2024-09-27' },
    [Stage.BETA]: { enabled: true, enableDate: '2024-09-27' },
    [Stage.GAMMA]: { enabled: true, enableDate: '2024-09-27' },
    [Stage.PROD]: { enabled: true, enableDate: '2024-10-01' },
  },
  [Feature.PROCESS_FLOW_TAB]: {
    [Stage.TEST]: { enabled: true, enableDate: '2024-10-10' },
    [Stage.ALPHA]: { enabled: true, enableDate: '2024-10-10' },
    [Stage.BETA]: { enabled: false, enableDate: 'N/A' },
    [Stage.GAMMA]: { enabled: false, enableDate: 'N/A' },
    [Stage.PROD]: { enabled: false, enableDate: 'N/A' },
  },
  [Feature.ADD_HOTEL_TO_OSCAR_TAGS]: {
    [Stage.TEST]: { enabled: true, enableDate: '2024-10-10' },
    [Stage.ALPHA]: { enabled: true, enableDate: '2024-10-10' },
    [Stage.BETA]: { enabled: true, enableDate: '2024-10-10' },
    [Stage.GAMMA]: { enabled: true, enableDate: '2024-10-10' },
    [Stage.PROD]: { enabled: true, enableDate: '2024-10-14' },
  },
  [Feature.PLAN_OTR_SUPPLY_TYPE]: {
    [Stage.TEST]: { enabled: true, enableDate: '2024-10-18' },
    [Stage.ALPHA]: { enabled: true, enableDate: '2024-10-18' },
    [Stage.BETA]: { enabled: true, enableDate: '2024-10-18' },
    [Stage.GAMMA]: { enabled: true, enableDate: '2024-10-18' },
    [Stage.PROD]: { enabled: false, enableDate: 'N/A' },
  },
};

export const isFeatureEnabled = (featureName: Feature) =>
  FEATURE_FLAG_MAP[featureName]['alpha'.toUpperCase() as Stage].enabled;
