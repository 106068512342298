import { cloneDeep } from 'lodash';
import { useMemo, useState } from 'react';
import { SLOT_DESCRIPTION_MAX_LEN } from 'src/constants';
import {
  PlanCreateFields,
  PlanCreateFieldIds,
  getDefaultCountries,
  getDefaultCurrencyOption,
  getDefaultPlanCycleOption,
  getDefaultPeriodTypeOption,
  getDefaultPlanYearOption,
  getDefaultStartEndDate,
} from 'src/pages/create-plan-page/createPlanBusinessLogic';
import {
  BUSINESS_GROUPS,
  PeriodTypeId,
  PLAN_TYPES,
  REGIONS,
  SUB_GROUPS,
} from 'src/utils/planning/planetModel';
import {
  BusinessFilterType,
  SUPPORTED_BUSINESS_GROUPS,
  getUpdatedSelectedGroupFilters,
} from 'src/utils/planning/supportedBusinessGroups';

export const transformFormFieldContent = (
  originalFormContent: PlanCreateFields,
  fieldId: PlanCreateFieldIds,
  newValue: any,
) => {
  let newFormContent = cloneDeep(originalFormContent);
  if (fieldId === PlanCreateFieldIds.PLAN_TYPE) {
    const updatedSelectedFilters = getUpdatedSelectedGroupFilters(
      originalFormContent,
      BusinessFilterType.PLAN_TYPE,
      newValue,
    );
    newFormContent = { ...newFormContent, ...updatedSelectedFilters };
  } else if (fieldId === PlanCreateFieldIds.BUSINESS_GROUP) {
    const updatedSelectedFilters = getUpdatedSelectedGroupFilters(
      originalFormContent,
      BusinessFilterType.BUSINESS_GROUP,
      newValue,
    );
    newFormContent = { ...newFormContent, ...updatedSelectedFilters };
  } else if (fieldId === PlanCreateFieldIds.SUB_GROUP) {
    const updatedSelectedFilters = getUpdatedSelectedGroupFilters(
      originalFormContent,
      BusinessFilterType.SUB_GROUP,
      newValue,
    );
    newFormContent = { ...newFormContent, ...updatedSelectedFilters };
  } else if (fieldId === PlanCreateFieldIds.REGION) {
    const updatedSelectedFilters = getUpdatedSelectedGroupFilters(
      originalFormContent,
      BusinessFilterType.REGION,
      newValue,
    );
    newFormContent = { ...newFormContent, ...updatedSelectedFilters };
  } else if (fieldId === PlanCreateFieldIds.COUNTRIES) {
    newFormContent.countries = newValue;
  } else if (fieldId === PlanCreateFieldIds.PLAN_CYCLE) {
    newFormContent.planCycle = newValue;
  } else if (fieldId === PlanCreateFieldIds.START_YEAR) {
    newFormContent.startYear = newValue;
  } else if (fieldId === PlanCreateFieldIds.PERIOD_TYPE) {
    newFormContent.periodType = newValue;
  } else if (fieldId === PlanCreateFieldIds.START_DATE) {
    newFormContent.startDate = newValue;
  } else if (fieldId === PlanCreateFieldIds.END_DATE) {
    newFormContent.endDate = newValue;
  } else if (fieldId === PlanCreateFieldIds.CURRENCY) {
    newFormContent.currency = newValue;
  } else if (fieldId === PlanCreateFieldIds.SLOT_DESCRIPTION) {
    newFormContent.slotDescription = newValue.slice(0, SLOT_DESCRIPTION_MAX_LEN);
  }

  const planTypeChanged = newFormContent.planType?.value !== originalFormContent.planType?.value;
  const planRegionChanged = newFormContent.region?.value !== originalFormContent.region?.value;

  if (planTypeChanged || planRegionChanged) {
    newFormContent.countries = getDefaultCountries(newFormContent);
  }

  if (planTypeChanged) {
    newFormContent.planCycle = getDefaultPlanCycleOption(newFormContent);
    newFormContent.startYear = getDefaultPlanYearOption();
    newFormContent.periodType = getDefaultPeriodTypeOption(newFormContent);
    newFormContent.currency = getDefaultCurrencyOption(newFormContent);
  }

  const planCycleChanged = newFormContent.planCycle?.value !== originalFormContent.planCycle?.value;
  const planPeriodTypeChanged =
    newFormContent.periodType?.value !== originalFormContent.periodType?.value;
  const planStartYearChanged =
    newFormContent.startYear?.value !== originalFormContent.startYear?.value;

  if (planCycleChanged || planPeriodTypeChanged || planStartYearChanged) {
    const { startDate, endDate } = getDefaultStartEndDate(newFormContent);
    newFormContent.startDate = startDate;
    newFormContent.endDate = endDate;
  }

  return newFormContent;
};

export const useCreatePlanFormManager = (queryParams: URLSearchParams) => {
  const initialCreateFields = useMemo(() => {
    const queryPlanType = queryParams.get(BusinessFilterType.PLAN_TYPE);
    const queryBusinessGroup = queryParams.get(BusinessFilterType.BUSINESS_GROUP);
    const querySubGroup = queryParams.get(BusinessFilterType.SUB_GROUP);
    const queryRegion = queryParams.get(BusinessFilterType.REGION);

    const queryParamsValid = SUPPORTED_BUSINESS_GROUPS.some(
      (item) =>
        item.planType === queryPlanType &&
        item.businessGroup === queryBusinessGroup &&
        item.subGroup === querySubGroup &&
        item.region === queryRegion,
    );

    let initialFields = { ...new PlanCreateFields() };

    if (queryParamsValid) {
      initialFields = transformFormFieldContent(
        initialFields,
        PlanCreateFieldIds.PLAN_TYPE,
        PLAN_TYPES.find((item) => item.value === queryPlanType) ?? null,
      );
      initialFields = transformFormFieldContent(
        initialFields,
        PlanCreateFieldIds.BUSINESS_GROUP,
        BUSINESS_GROUPS.find((item) => item.value === queryBusinessGroup) ?? null,
      );
      initialFields = transformFormFieldContent(
        initialFields,
        PlanCreateFieldIds.SUB_GROUP,
        SUB_GROUPS.find((item) => item.value === querySubGroup) ?? null,
      );
      initialFields = transformFormFieldContent(
        initialFields,
        PlanCreateFieldIds.REGION,
        REGIONS.find((item) => item.value === queryRegion) ?? null,
      );
    }

    return initialFields;
  }, [queryParams]);

  const [formContent, setFormContent] = useState(initialCreateFields as PlanCreateFields);

  const onUpdateFormField = (fieldId: PlanCreateFieldIds, newValue: any) => {
    const newFormContent = transformFormFieldContent(formContent, fieldId, newValue);
    setFormContent(newFormContent);
  };

  const canCreatePlan = !!(
    formContent.businessGroup &&
    formContent.countries.length !== 0 &&
    (formContent.startDate.length === 10 ||
      ((formContent?.periodType?.value === PeriodTypeId.MONTHLY ||
        formContent?.periodType?.value === PeriodTypeId.QUARTERLY) &&
        formContent.startDate.length === 7)) &&
    (formContent.endDate.length === 10 ||
      ((formContent?.periodType?.value === PeriodTypeId.MONTHLY ||
        formContent?.periodType?.value === PeriodTypeId.QUARTERLY) &&
        formContent.endDate.length === 7)) &&
    formContent.periodType &&
    formContent.planCycle &&
    formContent.region &&
    formContent.subGroup &&
    formContent.planType &&
    formContent.currency &&
    formContent.startDate <= formContent.endDate
  );

  return {
    canCreatePlan,
    formContent,
    onUpdateFormField,
  };
};
