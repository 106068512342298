import { SourceLocator, UploadBatchEditsResponse } from '@amzn/fox-den-cost-planning-lambda';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { FoxPlanningApi } from 'src/api/foxPlanningApi';
import { MutationHookOptions, QUERY_KEYS, useMutationWithNotifications } from 'src/api/api-utils';
import { useUserAlias } from 'src/hooks/useUserAlias';
import { SupportedDatasetType } from 'src/utils/planning/planetModel';

export enum OperationType {
  UPSERT = 'UPSERT',
  DELETE = 'DELETE',
  REPLACE = 'REPLACE',
}

export interface IFileUploadArguments {
  batchId: string;
  headers: string[];
  batchEditsFile: SourceLocator;
  datasetType: SupportedDatasetType;
  lastUpdatedOn: string;
  partitionS3VersionMap: Record<string, string> | null;
  partitionLocator: SourceLocator | null;
  operation?: OperationType;
}

export interface RowEdit {
  previous: string[];
  current: string[];
}

export type DatasetInlineEdits = RowEdit[];

export interface IInlineEditsUploadArguments {
  batchId: string;
  headers: string[];
  batchEdits: DatasetInlineEdits;
  datasetType: SupportedDatasetType;
  lastUpdatedOn: string;
  partitionS3VersionMap: Record<string, string> | null;
  partitionLocator: SourceLocator | null;
  operation?: OperationType;
}

export const useUploadEditsMutation = (
  options: MutationHookOptions<
    IFileUploadArguments | IInlineEditsUploadArguments,
    UploadBatchEditsResponse
  > = {},
) => {
  const queryClient = useQueryClient();
  const { alias } = useUserAlias();
  const { t } = useTranslation();

  return useMutationWithNotifications({
    ...options,
    meta: {
      inProgressNotificationText: t('api_in_progress_upload_edits'),
      errorNotificationText: t('api_error_upload_edits'),
      successNotificationText: t('api_success_upload_edits'),
    },
    mutationFn: (input) =>
      FoxPlanningApi.uploadBatchEdits({
        ...input,
        batchVersion: '0',
        requesterIdentity: alias,
        operation: input.operation ?? OperationType.UPSERT,
      }),
    onSettled(data, error, variables, context) {
      if (!options.onSettled) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.PLAN_DATASET, variables.batchId, variables.datasetType],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.BATCH_METADATA, variables.batchId],
        });
      }
      options?.onSettled?.(data, error, variables, context);
    },
  });
};
