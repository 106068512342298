/**
 * URL's
 */
export const PLANET_URL = {
  TICKET_URL: 'https://t.corp.amazon.com/create/templates/5c054e38-283f-4c14-ad4b-d9cf2ff59727',
  RIVER_URL:
    'https://river.amazon.com/workflow/MRS1/run/wfss-efc633d6-7ba7-4bfd-8a48-1554285c2a3c/?buildingType=OPSFSA&q0=3b73a9de-7a21-4b9d-85f4-a5a9f52d9dcd&q1=42b903b5-6948-4878-a377-786bbde9a74b&q2=&q3=&q4=',
  CONTACT_URL: 'https://w.amazon.com/bin/view/FOX/Contact/',
  PHONE_TOOL_URL: 'https://phonetool.amazon.com/users',
  WIKI_URL: 'https://w.amazon.com/bin/view/OpsS%26APlanning/PLANet',
  PRODUCT_OVERVIEW: 'https://w.amazon.com/bin/view/OpsS&APlanning/PLANet/ProductOverview/',
  USER_GUIDE: 'https://w.amazon.com/bin/view/PLANet/Userguide/',
  ROADMAP: 'https://w.amazon.com/bin/view/OpsS&APlanning/PLANet/WhatsNext/',
  SUCCESS_STORIES: 'https://w.amazon.com/bin/view/OpsS&APlanning/PLANet/SuccessStories/',
  STANDARDIZATION_AND_AUTOMATION_PRODUCTS: 'https://w.amazon.com/bin/view/S&A/Products/',
  CORP_FP_AND_A_CALENDAR: 'https://starmap.fgbs.amazon.dev/calendar',
  REFINE_EXCEL_TRANSFORMATION_TOOL: 'https://refine.ops-finance.amazon.dev/',
  CAPEX_OVERVIEW: 'https://w.amazon.com/bin/view/OpsS&APlanning/PLANet/Capex/Overview/',
  REAL_ESTATE_OVERVIEW:
    'https://w.amazon.com/bin/view/OpsS%26APlanning/PLANet/RealEstate-Depreciation/REOverview/',
  FIXED_DEPRECIATION_OVERVIEW:
    'https://w.amazon.com/bin/view/OpsS&APlanning/PLANet/Depreciation/Overview/',
  UNDER_THE_ROOF_OVERVIEW: 'https://w.amazon.com/bin/view/OpsS&APlanning/PLANet/UTR/UTRVariable/',
  HC_AND_RELATED_OPEX_OVERVIEW: 'https://apliqo-corp.fpa.corp.amazon.com/Assembly/#!/',
  ON_THE_ROAD_OVERVIEW: 'https://w.amazon.com/bin/view/OpsS&APlanning/PLANet/OTR/',
};
