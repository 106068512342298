export enum BaselineConfigObjects {
  CONDITIONS_CONFIG = 'conditionsConfig',
  PLAN_LOGIC_CONFIG = 'planLogicConfig',
}

export enum BaselineColumnIds {
  EXECUTING_ORDER = 'executingOrder',
  SOURCE_TYPE = 'sourceType',
  BATCH_NAME = 'batchName',
  BATCH_ID = 'batchId',
  DATE = 'date',
  BATCH_VERSION = 'batchVersion',
  TAGS = 'tags',
  COUNTRY_LIST = 'countryList',
  SUB_GROUP_TYPE_LIST = 'locationTypeList',
  BUILDING_STATUS_LIST = 'buildingStatusList',
  LOCATION_LIST = 'locationList',
  METRIC_GROUP_LIST = 'metricGroupList',
  METRIC_LIST = 'metricsNameList',
  ACCOUNT_LIST = 'accountList',
  SOURCE_LOGIC = 'sourceLogic',
  SOURCE_PERIOD_LIST = 'sourcePeriodList',
  DESTINATION_PERIOD_LIST = 'destinationPeriodList',
  PERCENTAGE_ADJUSTMENT = 'percentageAdjustment',
  UPDATE_DATASET_ON_SAVE = 'shouldUpdateDownstream',
  MODIFIED_BY = 'modifiedBy',
  MODIFIED_AT = 'modifiedAt',
}

export enum BaselineColumnNames {
  EXECUTING_ORDER = 'Execution Order',
  SOURCE_TYPE = 'Source Type',
  BATCH_NAME = 'Name',
  TAGS = 'Tags',
  DATE = 'Date',
  COUNTRY_LIST = 'Country',
  SUB_GROUP_TYPE_LIST = 'Sub Group Type',
  BUILDING_STATUS_LIST = 'Building Status',
  LOCATION_LIST = 'Location',
  METRIC_GROUP_LIST = 'Metric Group',
  METRIC_LIST = 'Metric',
  ACCOUNT_LIST = 'Account',
  SOURCE_LOGIC = 'Source Logic',
  SOURCE_PERIOD_LIST = 'Source Period',
  DESTINATION_PERIOD_LIST = 'Destination Period',
  PERCENTAGE_ADJUSTMENT = 'Baseline Adjustment %',
  UPDATE_DATASET_ON_SAVE = 'Update Dataset On Save',
  MODIFIED_BY = 'Modified By',
  MODIFIED_AT = 'Modified At',
}

export enum BaselineDatasourceNames {
  PLAN = 'Plan',
  COGNOS_ACTUALS = 'Cognos Actuals',
  COGNOS_FORECAST = 'Cognos Forecast',
  VARIABLE_PLAN = 'Variable Plan',
  PRODUCTIVITY_PLAN = 'Productivity Plan',
  PRODUCTIVITY_OUTPUT = 'Productivity Output',
  SNAPSHOT_DATE = 'Snapshot Date',
  CAPEX_PLAN = 'Capex Plan',
  DEPRECIATION_PLAN = 'Depreciation Plan',
}
