import { createContext, FC, PropsWithChildren, useCallback, useEffect } from 'react';
import { LOCAL_STORAGE_KEYS } from 'src/hooks/local-storage/localstoragekeys';
import { useLocalStorage } from 'src/hooks/local-storage/useLocalStorage';

interface DarkModeContextType {
  isDarkMode: boolean;
  toggleDarkMode(): void;
}
/**
 * React context for Dark Mode
 */
export const DarkModeContext = createContext<DarkModeContextType>({
  isDarkMode: false,
  toggleDarkMode: () => {},
});

/**
 * Wrapper to allow dark mode in application
 */
export const DarkModeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isDarkMode, setDarkMode] = useLocalStorage(LOCAL_STORAGE_KEYS.darkModePreference, false);

  const toggleDarkMode = useCallback(() => {
    if (isDarkMode) {
      setDarkMode(false);
    } else {
      setDarkMode(true);
    }
  }, [isDarkMode, setDarkMode]);

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('awsui-polaris-dark-mode');
    } else {
      document.body.classList.remove('awsui-polaris-dark-mode');
    }
  }, [isDarkMode]);

  return (
    <DarkModeContext.Provider
      value={{
        isDarkMode,
        toggleDarkMode,
      }}
    >
      {children}
    </DarkModeContext.Provider>
  );
};
