import { StatusIndicator } from '@amzn/awsui-components-react/polaris';
import { Dispatch, RefObject, SetStateAction, useMemo, useRef } from 'react';
import { ColDef, NewValueParams } from 'ag-grid-community';
import { cloneDeep } from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import { AgGridBaseWrapper } from 'src/common/ag-grid/AgGridBaseWrapper';
import { agRowInlineEditsUpdate } from 'src/common/ag-grid/row-validation-grid/AgRowValidationEditHelpers';
import {
  IRowValidationCellData,
  IRowValidationRowWithId,
  IValidationRowTransformer,
  isErrorRow,
  navigateToNextErrorRowIds,
} from 'src/common/ag-grid/row-validation-grid/AgRowValidators';
import { useDarkModeContext } from 'src/hooks/useDarkModeContext';
import { AG_INPUT_PAGINATION_PAGE_SIZE } from 'src/pages/plan-manager-page/plan-input-tab/planInputConfig';
import { useAgGridResizeColumns } from 'src/common/ag-grid/useAgGridResizeColumns';
import { useAgGridCalculatedHeight } from 'src/common/ag-grid/useAgGridCalculatedHeight';
import { Feature, isFeatureEnabled } from 'src/utils/featureFlag';

interface IAgRowValidationGrid {
  validationRowTransformer?: IValidationRowTransformer;
  rowData: any;
  gridRef: RefObject<AgGridReact>;
  columnDefs: ColDef[];
  isFullScreen: boolean;
  setIsFullScreen: any;
  defaultExcelExportParams: any;
  defaultCsvExportParams: any;
  validHeaders: string[];
  setInlineEdits: any;
  getNextRowId: () => number;
  inlineEditsDisabled: boolean;
  isShowingFilterComponent: boolean;
  savedUnsolvedErrorRowIds: Set<number>;
  setSavedUnsolvedErrorRowIds: Dispatch<SetStateAction<Set<number>>>;
}

const AgRowValidationGrid = (props: IAgRowValidationGrid) => {
  const {
    validationRowTransformer,
    rowData,
    gridRef,
    columnDefs,
    isFullScreen,
    setIsFullScreen,
    defaultExcelExportParams,
    defaultCsvExportParams,
    validHeaders,
    setInlineEdits,
    getNextRowId,
    inlineEditsDisabled,
    isShowingFilterComponent,
    savedUnsolvedErrorRowIds,
    setSavedUnsolvedErrorRowIds,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  const { isDarkMode } = useDarkModeContext();

  const genericValueSetter = (params: any) => {
    const data = params.data;
    const field = params.colDef.field;
    const newValue =
      !params.newValue || (params.newValue === '') === undefined ? null : params.newValue.trim();

    if (params.oldValue === newValue) {
      return false;
    }

    const newData: IRowValidationCellData = {
      value: newValue,
      original: data[field].original,
      lastValidationSuccess: true,
      validationErrors: [],
    };

    data[field] = newData;

    params.api.applyTransaction({ update: [data] });

    return true;
  };

  const genericValueGetter = (params: any) => params.data?.[params.colDef.field]?.value ?? '';

  const genericOnCellValueChanged = (params: NewValueParams) => {
    const data: IRowValidationRowWithId = params.data;

    if (!validationRowTransformer) return;

    const oldData = cloneDeep(data);
    if (params?.colDef?.field ?? '' in oldData) {
      oldData[params.colDef.field!].value = params.oldValue;
    }

    const formattedRow = validationRowTransformer(data);

    const inlineEditsUpdater = agRowInlineEditsUpdate(formattedRow, validHeaders, oldData);
    setInlineEdits(inlineEditsUpdater);

    params.api.applyTransaction({ update: [formattedRow] });
    params.api.refreshCells({ rowNodes: [params.node!], force: true });

    /** if this cell value change cleared a saved & unsolved error row */
    if (savedUnsolvedErrorRowIds.has(formattedRow.id.value) && !isErrorRow(formattedRow)) {
      /** update savedUnsolvedErrorRowIds */
      const newErrorRowIds = new Set(savedUnsolvedErrorRowIds);
      newErrorRowIds.delete(formattedRow.id.value);
      setSavedUnsolvedErrorRowIds(newErrorRowIds);

      /** navigate to the next error row */
      navigateToNextErrorRowIds(gridRef, newErrorRowIds);
    }
  };

  const genericCellStyle = (params: any) => {
    const data = params.data?.[params.colDef.field];

    if (!data) return null;

    if (!data.lastValidationSuccess) {
      return { backgroundColor: isDarkMode ? '#301934' : 'mediumpurple' };
    }

    if (data.value !== data.original) {
      return { backgroundColor: isDarkMode ? '#808080' : 'lightyellow' };
    }

    return null;
  };

  const MyCustomTooltip = (params: any) => (
    <div
      style={{
        backgroundColor: '#21252c',
        padding: '8px',
        border: '1px solid #545b64',
      }}
    >
      {params.value.map((message: any) => (
        <div key={message} style={{ padding: '2px' }}>
          <StatusIndicator type="error">{message}</StatusIndicator>
        </div>
      ))}
    </div>
  );

  const defaultColDef: ColDef = {
    sortable: true,
    resizable: true,
    editable: !inlineEditsDisabled,
    filter: true,
    onCellValueChanged: genericOnCellValueChanged,
    valueGetter: genericValueGetter,
    valueSetter: genericValueSetter,
    cellStyle: genericCellStyle,
    tooltipComponent: MyCustomTooltip,
    tooltipValueGetter: (params) => {
      try {
        const data = params.data[(params.colDef as ColDef).field!];
        if (!data.lastValidationSuccess) {
          return data.validationErrors;
        }
      } catch (_) {
        // do nothing
      }
      return '';
    },
  };

  const { resizeColumns } = useAgGridResizeColumns(gridRef, containerRef, rowData);
  const { gridHeight } = useAgGridCalculatedHeight(containerRef, 65, isShowingFilterComponent);

  const getRowId = useMemo(
    () => (params: any) => params?.data?.id?.value ?? getNextRowId(),
    [getNextRowId],
  );

  return (
    <div ref={containerRef}>
      <AgGridBaseWrapper
        gridRef={gridRef}
        rowBuffer={10}
        tooltipShowDelay={1000}
        suppressColumnVirtualisation={true}
        onFirstDataRendered={resizeColumns}
        onGridSizeChanged={resizeColumns}
        onRowDataUpdated={resizeColumns}
        enableRangeSelection={true}
        enableRangeHandle={true}
        gridHeight={gridHeight}
        isFullScreen={isFullScreen}
        setFullScreen={setIsFullScreen}
        pagination={true}
        paginationPageSize={AG_INPUT_PAGINATION_PAGE_SIZE}
        defaultColDef={defaultColDef}
        rowSelection="multiple"
        animateRows={true}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultExcelExportParams={defaultExcelExportParams}
        defaultCsvExportParams={defaultCsvExportParams}
        getRowId={getRowId}
        suppressRowClickSelection={true}
      />
    </div>
  );
};
export default AgRowValidationGrid;
