import { useMemo, useState } from 'react';

export const save = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const remove = (key: string) => localStorage.removeItem(key);

export const load = (key: string) => {
  const value = localStorage.getItem(key);
  try {
    return value && JSON.parse(value);
  } catch (_) {
    console.warn(
      `⚠️ The ${key} value that is stored in localStorage is incorrect. Try to remove the value ${key} from localStorage and reload the page`,
    );
    return undefined;
  }
};

/**
 * Local storage manager that syncs a value to localstorage and uses the default
 * value if localstorage has no value for the key.
 * @returns value and a handleValueChange function which syncs the value to localstorage
 */
export const useLocalStorage = <T>(key: string, defaultValue?: T) => {
  const [value, setValue] = useState<T>(() => load(key) ?? defaultValue);

  function handleValueChange(newValue: T) {
    setValue(newValue);
    save(key, newValue);
  }

  return [value, handleValueChange] as const;
};

/**
 * Local storage manager hook that syncs a value to localstorage and has different
 * initialization behavior based on the arguments passed and the whether the user
 * has data associated with the key in localstorage.
 *
 * If a non-nullish override value is passed, it will always take precidence.
 * If the override value is nullish, localstorage is checked for the value and returned
 * if found.
 * If both override value and localstore are nullish, the passed default value will be used
 *
 * @returns value and a handleValueChange function which syncs the value to localstorage
 */
export const useLocalStoragePreferOverride = <T>(key: string, override: T, defaultValue?: T) => {
  const preferredInitializer = useMemo(() => {
    if (override) {
      return override;
    }
    return load(key) ?? defaultValue;
  }, [defaultValue, key, override]);

  const [value, setValue] = useState<T>(preferredInitializer);

  function handleValueChange(newValue: T) {
    setValue(newValue);
    save(key, newValue);
  }

  return [value, handleValueChange] as const;
};
